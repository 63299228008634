<template>
    <div>
        <PopupDialog :dialogOpen="dialog"/>
        <Navbar />
        <v-row justify="center" class="ma-0">
            <v-col cols=12 md=6>
                <v-alert v-if="error" type="error" outlined>{{ error }}</v-alert>
            <v-card class="py-10 px-5 mx-3" flat style="border-radius: 20px">
                <div class="primary--text display-1 ml-5 mb-5 font-weight-medium">{{ $t("passwordRecovery.recover") }}</div>
                <div class="body grey--text text--darken-2 mb-3 ml-2">{{ $t("passwordRecovery.enterNewPassword") }}</div>
                <v-form class="px-3" ref="recoveryForm" @submit.prevent="recoverCode">
                    <InputField 
                        rules="minLength"
                        :label="this.$t('newPassword')"
                        icon="lock"
                        v-model="password"
                        inputType="password"
                    />
                    <v-row justify="center">
                        <v-btn depressed rounded color="primary" class="mx-0 px-10" type="submit">{{ $t("passwordRecovery.changePassword") }}</v-btn>
                    </v-row>
                </v-form>
            </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Navbar from '@/components/partials/Navbar'
import InputField from '@/components/partials/InputField'
import PopupDialog from '@/components/partials/PopupDialog'
import axios from 'axios'
    export default {
        name: "PasswordRecoveryCode",
        components: {Navbar, InputField, PopupDialog},
        data() {
            return {
                password: '',
                error: '',
                dialog: false
            }
        },
        methods: {
            recoverCode: function() {
                if (this.$refs.recoveryForm.validate()) {
                    let data_to_post = {
                        password: this.password,
                        code: this.$route.params.code
                    }
                    axios.post('/reset_password_code', data_to_post)
                    .then(() => {
                        this.error = ''
                        this.$router.push({name: 'recover_success'})
                    })
                    .catch(error => {
                        if (error.response && error.response.data && error.response.data.message) {
                            if (error.response.data.message == 'Invalid code') {
                                this.error = this.$t("passwordRecovery.codeUsed")
                            } else if (error.response.data.message == 'INVALID DATA: code must be of type string' || error.response.data.message == 'Checksum failed') {
                                this.error = this.$t("passwordRecovery.invalidCode")
                            } else {
                                this.dialog = true
                            }
                        } else {
                            this.dialog = true
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>